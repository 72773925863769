import React, { useState, useEffect, useContext } from "react";
import Tags1 from "../CIOnboarding/Tags1";
import ResourceTagInput from "./ResourceTagInput";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Box,
  TextField,
  Typography,
  Card,
  CardContent,
  CardActions,
  Grid,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import UntagFeature from "./UntagFeature";
import { TableContext } from "../../context";

function ResourceTag({ data, onClose }) {
  const [selectedItems, setSelectedItems] = useState([]);
  const [tableData, setTableData] = useState(data);
  const [resourceMap, setResourceMap] = useState([]);
  const [reload, setReload] = useState(false);
  const { graphData, ownedApplications, setOwnedApplications } =
    useContext(TableContext);

  const handleTagClick = async () => {
    const requestBody = {
      email:graphData?.mail,
      user_name: graphData?.displayName,
      data: data,
      tagging_applications: selectedItems,
      untagging_resources: resourceMap,
    };
    console.log("Request Body: ", requestBody);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/tag_resources`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(requestBody),
        }
      );
      console.log("Response: ", response);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      if (responseData.status === "Success") {
        toast.success("Resources tagged/untagged successfully");
       await  onClose();
      }
      if (responseData.status === "Failure") {
        toast.error("Resources tagging/untagging failed");
        setReload((prev) => !prev); // Trigger reload
      }

      console.log("Success:", responseData);
    } catch (error) {
      console.error("Error:", error);
      setReload((prev) => !prev); // Trigger reload
    }
  };

  useEffect(() => {
    console.log("Data", ownedApplications);
  }, [ownedApplications, reload]); // Add reload to dependencies

  return (
    <div>
      <div className="server-info-item">
        <p className="server-info-label">Select an application to tag</p>
        <ResourceTagInput
          type="text"
          name="u_used_by_business_unit"
          value={""}
          disabled={false}
          selectedItems={selectedItems}
          ownedApplications={ownedApplications}
          setSelectedItems={setSelectedItems}
          style={{
            borderRadius: "8px",
            border: "1px solid #ccc",
            padding: "5px",
            width: "60%",
            backgroundColor: "white",
            textAlign: "center",
            fontSize: "1rem",
          }}
        />
      </div>
      <div
        style={{
          marginBottom: "15px",
          marginTop: "15px",
          display: "flex",
          justifyContent: "left",
        }}
      >
        <b>Note: &nbsp;</b> You can only tag/untag resources to the application
        that you own.
      </div>

      <div style={{ marginTop: "20px" }}>
        <h4 style={{ textAlign: "left" }}>Selected Resources</h4>
        <hr />
        <div
          style={{ maxHeight: "200px", overflowY: "auto", marginTop: "10px" }}
        >
          <UntagFeature
            data={data}
            tableData={tableData}
            setTableData={setTableData}
            resourceMap={resourceMap}
            setResourceMap={setResourceMap}
            ownedApplications={ownedApplications}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
        }}
      >
        <Button sx={{ color: "#00243D" }} onClick={async()=>{
          
          await toast.promise(handleTagClick(), {
            pending: "Processing resources...",
          });
        }}
          
         >
          Save
        </Button>
        <Button sx={{ color: "#00243D" }} onClick={onClose}>
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default ResourceTag;
