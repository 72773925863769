import React, { useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const UntagFeature = ({
  data,
  resourceMap,
  setResourceMap,
  ownedApplications,
}) => {
  const [tableData, setTableData] = useState(data);

  const handleUntag = (resourceName, appIdToRemove) => {
    if (!ownedApplications?.applicationIds?.includes(appIdToRemove)) {
      toast.error(`You are not the owner of the specific app ID: ${appIdToRemove}`);
      return;
    }
    console.log("Untagging: ", resourceName, appIdToRemove);
    setTableData((prevData) =>
      prevData.map((row) => {
        if (row["Resource Name"] === resourceName) {
          const updatedAppIds = row["Application IDs"].filter(
            (id) => id !== appIdToRemove
          );
          updateResourceMap(
            resourceName,
            row["Application IDs"],
            row["Type"],
            row['Resource Group Names'],
            row["Admiral Product ID"],
            row[ "Account ID"],
            updatedAppIds
          );
          return { ...row, "Application IDs": updatedAppIds };
        }
        return row;
      })
    );
  };

  const updateResourceMap = (resourceName, previousIds, type,resourcegropuname,admiralproductid,accountid, newIds) => {
    console.log("Updating Resource Map: ", resourceName, previousIds, newIds);
    setResourceMap((prevMap) => {
      const existingEntry = prevMap.find(
        (item) => item["Resource Name"] === resourceName
      );
      if (existingEntry) {
        return prevMap.map((item) =>
          item["Resource Name"] === resourceName
            ? { ...item, newId: newIds }
            : item
        );
      }
      return [
        ...prevMap,
        { "Resource Name": resourceName, 
            Type: type,
             "Resource Group Names": resourcegropuname,
                "Admiral Product ID": admiralproductid,
                "Account ID": accountid,
             newId: newIds },
      ];
    });
  };

  const saveChanges = () => {
    console.log("Final Resource Map: ", resourceMap);
    // Perform backend API call here
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Resource Name</TableCell>
              <TableCell>Resource Type</TableCell>
              <TableCell>App IDs</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((resource) => (
              <TableRow key={resource["Resource Name"]}>
                <TableCell>{resource["Resource Name"]}</TableCell>
                <TableCell>{resource["Type"]}</TableCell>
                <TableCell>
                  {resource["Application IDs"].map((appId) => (
                    <span key={appId} style={{ margin: "0 5px" }}>
                      {appId}
                      <IconButton
                        onClick={() =>
                          handleUntag(resource["Resource Name"], appId)
                        }
                        size="small"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </span>
                  ))}
                </TableCell>
                <TableCell>
                  {resource["Application IDs"].length > 0
                    ? "Untag available"
                    : "No App IDs"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <Button variant="contained" color="primary" onClick={saveChanges} style={{ marginTop: "20px" }}>
                Save Changes
            </Button> */}
    </div>
  );
};

export default UntagFeature;
