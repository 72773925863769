import React, { useState, useContext, useRef, useEffect } from "react";
import "../App.css";
import { ToastContainer, toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom";
import ProfilePicture from "./ProfilePicture";
import { TableContext } from "../context";
import NotificationBadge from "react-notification-badge";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// Import Subscribe/Unsubscribe handling functions
import {
  handleSubscribe,
  handleUnsubscribe,
  handleShowSubscribe,
} from "./SubscriptionHandlers"; // Import these from a separate file.
import { FaSleigh } from "react-icons/fa";
 
/**
   * Represents the navigation bar component.
   *
   * @component
   * @param {Object} props - The component props.
   * @returns {JSX.Element} The rendered Navbar component.
   */
/**
 * Navbar component that renders the navigation bar for the CMDB Portal.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.run - A boolean to control the tour functionality.
 * @param {function} props.setRun - A function to toggle the tour functionality.
 *
 * @returns {JSX.Element|null} The rendered Navbar component or null if the path is "/" or "*".
 *
 * @example
 * <Navbar run={run} setRun={setRun} />
 *
 * @description
 * This component handles the rendering of the navigation bar, including user profile dropdown,
 * subscription management, and various navigation links. It also manages the state for dropdown
 * visibility, subscriptions, and handles various user interactions such as logout and window resize.
 *
 * @requires useLocation - React Router hook to get the current location.
 * @requires useState - React hook to manage state.
 * @requires useContext - React hook to access context.
 * @requires useRef - React hook to create a reference.
 * @requires useEffect - React hook to handle side effects.
 * @requires TableContext - Context to access table-related data and functions.
 * @requires Link - React Router component for navigation.
 * @requires NotificationBadge - Component to display notification badges.
 * @requires ProfilePicture - Component to display user's profile picture.
 * @requires Dialog - Material-UI component for dialog (popup).
 * @requires DialogTitle - Material-UI component for dialog title.
 * @requires DialogContent - Material-UI component for dialog content.
 * @requires IconButton - Material-UI component for icon button.
 * @requires CloseIcon - Material-UI icon for close button.
 * @requires Button - Material-UI component for button.
 * @requires toast - Function to display toast notifications.
 *
 * @function handleDropdownToggle - Toggles the dropdown open or closed.
 * @function handleDropdownClose - Closes the dropdown.
 * @function handleClickOutside - Handles the click outside event for the dropdown.
 * @function handleClickOpen - Fetches the subscriptions and opens the popup.
 * @function handleClose - Closes the popup.
 * @function handleLogout1 - Handles the logout action.
 *
 * @hook useEffect - Adds and removes event listeners for dropdown and window resize.
 */
export default function Navbar(props) {
  const location = useLocation();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const { graphData } = useContext(TableContext);
  const [moreDropdownOpen, setMoreDropdownOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const {
    table1,
    setTable1,
    applicationName,
    setapplicationName,
    relationshipCategorySelected,
    setRelationshipCategorySelected,
    setDeletedRows,
    showCommit,
    setshowCommit,
    handleLogout,
    isOwner,
    count,
    getCount,fetchWithTimeout,
    setShowSubscribe,
    setShowUnsubscribe
  } = useContext(TableContext);
  // console.log(location);
  const dropdownRef = useRef(null);
  const dropdownRef2 = useRef(null);
  const [showSubscribe1, setShowSubscribe1] = useState();
  const [showUnsubscribe1, setShowUnSubscribe1] = useState();
  /**
     * Toggles the dropdown open or closed.
     */
  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  /**
   * Closes the dropdown.
   */
  const handleDropdownClose = () => {
    setDropdownOpen(false);
  };
  /**
   * Handles the click outside event for the dropdown.
   * Closes the dropdown if the click is outside the dropdown element.
   *
   * @param {Event} event - The click event.
   */
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      handleDropdownClose();
    }
  };
 
  // Fetch the subscriptions and open the popup
  const handleClickOpen = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/getAllSubscriptions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            email: graphData?.mail, // Send the email of the user
          }),
        }
      );
 
      if (response.ok) {
        const result = await response.json();
        setSubscriptions(result.data); // Set the subscriptions state with the response data
        // For each subscription, call the logic to decide if the user can subscribe/unsubscribe
        result.data.forEach((app) => {
          setShowUnSubscribe1((prevState) => ({ ...prevState, [app]: true }));
          setShowSubscribe1((prevState) => ({ ...prevState, [app]: false }));
        });
        setOpen(true); // Open the popup
      } else {
        console.error("Failed to fetch subscriptions");
      }
    } catch (error) {
      toast.error("Failed to fetch Subscriptions", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
 
  const handleClose = () => {
    setOpen(false); // Closes the popup
  };
 
  // const toggleMoreDropdown = () => setMoreDropdownOpen(!moreDropdownOpen);
 
  const handleClickOutsideMore = (event) => {
    if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
      setMoreDropdownOpen(false);
    }
  };
 
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideMore);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideMore);
    };
  }, [moreDropdownOpen]);
 
  const handleMoreDrodownToggle = () => {
    setMoreDropdownOpen(!moreDropdownOpen);
  }
 
  const handleClickMore = (event) => {
    event.preventDefault();
  }
 
  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    // Cleanup function for the effect hook
    return () => {
      // Remove the event listener for mousedown
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // This effect hook runs when isDropdownOpen changes
  }, [isDropdownOpen]);
 
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      console.log(window.innerWidth);
      setMoreDropdownOpen(false);
    };
 
    // Add event listener
    window.addEventListener("resize", handleResize);
 
    // Cleanup function
    return () => {
      // Remove event listener
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  /**
   * Handles the logout action.
   */
  const handleLogout1 = () => {
    setTable1([]);
    // setGraphData(null)
    setapplicationName("");
    handleLogout();
  };
  // If the current path is /signin or *, return null
  if (location.pathname === "/" || location.pathname === "*") return null;
  // Otherwise, render the navbar
  else
    return (
      <div>
        <nav className="navbar">
          <div className="nav-bar">
            <img
              src="./images/Maersk_Logo_RGB.svg"
              alt=" "
              height="50"
              width="120"
              style={{}}
            />
            <span className="logo navLogo">
              <Link to="/">MSA</Link>
              <p></p>
            </span>
            <div className="menu">
              {/* <div className="logo-toggle">
                  <span className="logo">
                    <a href="/">CMDB Portal</a>
                  </span>
                  <i className="bx bx-x siderbarClose"></i>
                </div> */}
              <ul className="nav-links">
                <li>
                  <Link to="/main">Search Relationship</Link>
                </li>
                {windowWidth >= 700 && (
                  <li>
                    <Link className="viewchanges" to="/ViewChanges">
                      View Proposed Relationships
                    </Link>
                  </li>
                )}
                {windowWidth >= 800 && isOwner && (
                  <li className="approvechanges">
                    <Link to="/ApproveChanges">
                      Approve Proposed Relationships
                    </Link>
                    <div>
                      <NotificationBadge
                        count={count}
                        style={{
                          position: 'absolute',
                          right: '-14px',
                          top: '-3px',
                        }}
                      />
                    </div>
                  </li>
                )}
                {windowWidth >= 1024 && (
                  <li className="certify">
                    <Link to="/certify">Certification</Link>
                  </li>
                )}
                {windowWidth < 1200 && (
                  <div ref={dropdownRef2} className="menu" style={{ position: 'relative', marginTop: '0px' }} onClick={handleMoreDrodownToggle}>
                  <li className="more">
                    <Link to="/More" onClick={handleClickMore}>
                      More...
                    </Link>
                  </li>
                    {moreDropdownOpen && (
                      <div className="dropdown-menu" style={{display:'flex', flexDirection:'column', width: 'fit-content'}}>
                        {windowWidth < 700 && (
                          <li>
                            <Link to="/ViewChanges" style={{marginLeft: '0px'}}>View Proposed Relationships</Link>
                          </li>
                        )}
                        {windowWidth < 800 && isOwner && (
                          <li>
                            <Link to="/ApproveChanges" style={{marginLeft: '0px'}}>Approve Proposed Relationships</Link>
                            <div>
                              <NotificationBadge
                                count={count}
                                style={{
                                  position: 'absolute',
                                  right: '-14px',
                                  top: '-3px',
                                }}
                              />
                            </div>
                          </li>
                        )}
                        {windowWidth < 1024 && (
                          <li>
                            <Link to="/certify"  style={{marginLeft: '0px'}}>Certification</Link>
                          </li>
                        )}
                        <li>
                        <Link to="/AssetExplorer" style={{marginLeft: '0px'}}>Infra Explorer</Link>
                        </li>
                        <li>
                          <Link to="/cionboarding" style={{marginLeft: '0px'}}>App Instance Update</Link>
                        </li>
                      </div>
                    )}
                  </div>
                )}
                {windowWidth >= 1200 && (
                  <>
                    <li className="certify">
                      <Link to="/AssetExplorer">Asset Explorer</Link>
                    </li>
                    <li className="certify">
                      <Link to="/cionboarding">App Instance Update</Link>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
          <div
            ref={dropdownRef}
            className="user-profile"
            onClick={handleDropdownToggle}
          >
            <span>{graphData?.displayName}</span>
            <ProfilePicture
              initials={graphData?.displayName
                .match(/(^\S\S?|\b\S)?/g)
                .join("")
                .match(/(^\S|\S$)?/g)
                .join("")
                .toUpperCase()}
              size={35}
            />
            {isDropdownOpen && (
              <div className="dropdown-menu">
                <ul>
                <li>
                  <Link
                    onClick={() => {
                      props.setRun(!props.run);
                    }}
                  >
                    Take a tour
                  </Link>
                </li>
                  <li>
                    <Link to="/ViewChanges">View Proposed Relationships</Link>
                  </li>
                  {isOwner && (
                    <li>
                      <Link to="/ApproveChanges">
                        Approve Proposed Relationships
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link to="/certify">Certification</Link>
                  </li>
                  <li>
                    <Link
                      to="/YourSubscriptions"
                      onClick={handleClickOpen} // Open the popup on click
                    >
                      Your Subscriptions
                    </Link>
                  </li>
                  <li>
                    <Link onClick={handleLogout1}>Logout</Link>
                  </li>
                </ul>
              </div>
            )}
            {/* Material-UI Dialog (Popup) */}
          </div>
          <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              style={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
            <DialogTitle>Your Subscriptions</DialogTitle>
            <DialogContent style={{ maxHeight: "300px", overflowY: "auto" }}>
              {subscriptions.length > 0 ? (
                <div>
                  <p>The applications you have subscribed to are:</p>
                  <ul style={{ paddingLeft: "20px", margin: "0" }}>
                    {subscriptions.map((app, index) => (
                      <li
                        key={index}
                        style={{
                          margin: "10px 0",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center", // Aligns vertically
                          padding: "10px", // Add padding for hover effect
                          borderRadius: "5px", // Rounding the edges for hover effect
                          transition: "background-color 0.3s ease", // Smooth transition for hover effect
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor = "#f5f5f5";
                          e.currentTarget.style.boxShadow =
                            "0px 5px 8px rgba(0, 0, 0, 0.3)";
                          e.currentTarget.style.transform = "translateY(-5px)";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor = "transparent";
                          e.currentTarget.style.boxShadow = "none";
                          e.currentTarget.style.transform = "translateY(0px)";
                        }}
                      >
                        <span>{app}</span>
                        {showSubscribe1[app] && !showUnsubscribe1[app] && (
                          <Button
                            onClick={async () => {
                              await handleSubscribe(app, graphData);
                              handleDropdownClose();
                             await handleShowSubscribe(
                                app,
                                graphData,
                                relationshipCategorySelected.value,
                                (show) => {
                                  setShowSubscribe1((prevState) => ({
                                    ...prevState,
                                    [app]: show,
                                  }));
                                },
                                (show) => {
                                  setShowUnSubscribe1((prevState) => ({
                                    ...prevState,
                                    [app]: show,
                                  }));
                                }
                              );
                              try {
                                const response = await fetchWithTimeout(
                                  `${process.env.REACT_APP_BACKEND_URI}/checkSubscriptionStatus`,
                                  {
                                    method: "POST",
                                    headers: {
                                      "Content-Type": "application/json",
                                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                                    },
                                    body: JSON.stringify({
                                      app_name: applicationName,
                                      email: graphData?.mail,
                                      relationship_category: relationshipCategorySelected.value,
                                    }),
                                  }
                                );
                                if (response.ok) {
                                  const subscriptionResult = await response.json();
                                  console.log(subscriptionResult);
                                  const subscriptionStatus = subscriptionResult.subscription_status;
                   
                                  if (subscriptionStatus == "Subscribed") {
                                    setShowSubscribe(false);
                                    setShowUnsubscribe(true);
                                  } else if (subscriptionStatus == "Not Subscribed") {
                                    setShowSubscribe(true);
                                    setShowUnsubscribe(false);
                                  } else {
                                    console.error("Failed to fetch subscription status");
                                  }
                                }
                              } catch (error) {
                                console.error("Error fetching subscription status:", error);
                                toast.error("Failed to fetch subscription status", {
                                  position: "top-center",
                                  autoClose: 3000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                  theme: "light",
                                });
                              }
                             
                            }}
                            variant="contained"
                            style={{
                              backgroundColor: "#42b0d5",
                              marginLeft: "10px",
                            }}
                          >
                            Subscribe
                          </Button>
                        )}
                        {showUnsubscribe1[app] && !showSubscribe1[app] && (
                          <Button
                            onClick={async () => {
                              await handleUnsubscribe(app, graphData);
                              handleDropdownClose();
                              await handleShowSubscribe(
                                app,
                                graphData,
                                relationshipCategorySelected.value,
                                (show) => {
                                  setShowSubscribe1((prevState) => ({
                                    ...prevState,
                                    [app]: show,
                                  }));
                                },
                                (show) => {
                                  setShowUnSubscribe1((prevState) => ({
                                    ...prevState,
                                    [app]: show,
                                  }));
                                }
                              );
                              try {
                                const response = await fetchWithTimeout(
                                  `${process.env.REACT_APP_BACKEND_URI}/checkSubscriptionStatus`,
                                  {
                                    method: "POST",
                                    headers: {
                                      "Content-Type": "application/json",
                                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                                    },
                                    body: JSON.stringify({
                                      app_name: applicationName,
                                      email: graphData?.mail,
                                      relationship_category: relationshipCategorySelected.value,
                                    }),
                                  }
                                );
                                if (response.ok) {
                                  const subscriptionResult = await response.json();
                                  console.log(subscriptionResult);
                                  const subscriptionStatus = subscriptionResult.subscription_status;
                   
                                  if (subscriptionStatus == "Subscribed") {
                                    setShowSubscribe(false);
                                    setShowUnsubscribe(true);
                                  } else if (subscriptionStatus == "Not Subscribed") {
                                    setShowSubscribe(true);
                                    setShowUnsubscribe(false);
                                  } else {
                                    console.error("Failed to fetch subscription status");
                                  }
                                }
                              } catch (error) {
                                console.error("Error fetching subscription status:", error);
                                toast.error("Failed to fetch subscription status", {
                                  position: "top-center",
                                  autoClose: 3000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                  theme: "light",
                                });
                              }
                            }}
                            variant="contained"
                            style={{
                              backgroundColor: "#cf482e",
                              marginLeft: "10px",
                            }}
                          >
                            Unsubscribe
                          </Button>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <p>No subscriptions found.</p>
              )}
            </DialogContent>
          </Dialog>
        </nav>
        {/* <Maintainance top={50} /> */}
      </div>
    );
}

 
 