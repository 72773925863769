import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CommonModal from "../CommonModal";
import { Button } from "@mui/material";
import ResourceTag from "./ResourceTag";
import ResourcePopUp from "./ResourcePopUp";

// CustomToolbar defined outside to prevent re-creation
const CustomToolbar = ({
  columns,
  selectedColumn,
  setSelectedColumn,
  operator,
  setOperator,
  onFilterApply,
  rowSelectionData,
  setopenserverenvmodal1

}) => {
  const filterInputRef = useRef();

  return (
    <GridToolbarContainer>
      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }}
        csvOptions={{ fileName: "exported_data" }}
      />
      <GridToolbarFilterButton />
      <Box ml="auto">
         { rowSelectionData?.length>0 && <Button variant="contained" sx={{backgroundColor:"#0073AB",
      margin: "0 10px",
         '&:hover': {
          transform: 'translateY(-1px)',
          boxShadow: 3,
          backgroundColor:"#00243D"
        },

      }} size="small" 
      onClick={()=>{
        setopenserverenvmodal1(true);

      }} >
                            Tag/Untag Selected Resources
                        </Button>}
        <GridToolbarQuickFilter />
      </Box>
    </GridToolbarContainer>
  );
};


function Compute(props) {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState("");
  const [operator, setOperator] = useState("contains");
  const [filterValue, setFilterValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [openserverenvmodal, setopenserverenvmodal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [rowSelectionData, setRowSelectionData] = React.useState([])
  const [openserverenvmodal1, setopenserverenvmodal1] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  });

  const handleFilter = useCallback(() => {
    let filtered = data;

    if (operator === "contains") {
      filtered = data?.filter((row) =>
        String(row[selectedColumn])
          .toLowerCase()
          .includes(filterValue.toLowerCase())
      );
    } else if (operator === "equals") {
      filtered = data?.filter((row) => String(row[selectedColumn]) === filterValue);
    }
    setFilteredData(filtered);
  }, [data, operator, selectedColumn, filterValue]);

  const handleFilterApply = useCallback(
    (newFilterValue) => {
      setFilterValue(newFilterValue);
      let filtered = data;

      if (operator === "contains") {
        filtered = data?.filter((row) =>
          String(row[selectedColumn])
            .toLowerCase()
            .includes(newFilterValue.toLowerCase())
        );
      } else if (operator === "equals") {
        filtered = data?.filter((row) => String(row[selectedColumn]) === newFilterValue);
      }

      setFilteredData(filtered);
    },
    [data, operator, selectedColumn]
  );

  const fetchData = async () => {
    setData([]);
    setFilteredData([]);
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URI}/dashboardData?function=compute_assets`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },        }
    );
    const data = await response.json();
    setData(data?.data);
    setFilteredData(data?.data);
    const columns = Object.keys(data?.data[0] || {});
    const desiredOrder = [
      "Resource Name",
      "Resource Group Names",
      "Type",
      "Cloud Provider",
      "Subscription Names",
      "Application IDs",
      "Account ID",
      "Portfolio Names",
      "Platform Names",
      "Region ID",
      "Region Name",
      "Resource ID",
      "Admiral Product ID",
    ]; // Replace with your desired column names
    // Reorder the columns array based on the desired order
    const orderedColumns = desiredOrder.filter((column) =>
      columns.includes(column)
    );
    setColumns(orderedColumns);
    setSelectedColumn(orderedColumns[0] || "");
  };

  useEffect(() => {
    
    fetchData();
  }, []);

  const handleRefresh = () => {
    console.log("Refreshed");
    fetchData();
  }


  function getRowId(row) {
    return row['Resource ID'];
  }

  const handleSelectionChange = (newSelection) => {
    const selectedRowsData = newSelection.map((id) => data?.find((row) => getRowId(row) === id));
    setRowSelectionData( selectedRowsData);
    console.log("Selected Rows Data:", rowSelectionData);
  };

  const handleLinkClick = (row) => {
    console.log("Row Data:", row);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "90vh", overflow: "auto" }}>
      <CommonModal
        isOpen={openserverenvmodal}
        onClose={() => setopenserverenvmodal(false)}
      >
        <ResourcePopUp rowData={selectedRowData} />
      </CommonModal>
      <CommonModal
        isOpen={openserverenvmodal1}
        onClose={() => setopenserverenvmodal1(false)}
      >
        <ResourceTag data={rowSelectionData} onClose={() => setopenserverenvmodal1(false)} />
      </CommonModal>
      <Box sx={{ display: "flex",height: "90%", width: "90%", minHeight: 300,flexDirection: "column"  }}>
      <Typography variant="h5" component="div" sx={{ mb: 2 }}>
          Compute
        </Typography>
        <Button
         variant="contained"
        sx={{
          backgroundColor: "#42b0d5",
          width: "fit-content",
          marginLeft: "auto",
          marginBottom: "5px",
          '&:hover': {
            transform: 'translateY(-1px)',
            boxShadow: 3,
            backgroundColor:"#00243D"
          },
        }}
        onClick={handleRefresh}
        
        >Refresh</Button>
        <DataGrid
        sx={{fontSize: "0.8em"}}
          loading={data?.length === 0}
          getRowId={getRowId}
          handleSelectionChange={handleSelectionChange}
          columns={columns
            ?.filter((column) => column !== "id")
            .map((column, index) => ({
              field: column,
              flex: 1,
              renderCell: (params) => {
                if (Array.isArray(params.value)) {
                  return params.value.join(", ");
                }
                if (index === 0) {
                  return (
                    <button
                      style={{ color: "#42b0d5", cursor: "pointer", background: "none", border: "none", padding: 0 }}
                      onClick={(e) => {
                        setopenserverenvmodal(true);
                        setSelectedRowData({
                          "Account ID": params.row["Account ID"],
                          "Resource Name": params.row["Resource Name"],
                        });
                        console.log("Row Data:", params.row);
                      }}
                    >
                      {params.value}
                    </button>
                  );
                }
                return params.value;
              },
              // disableColumnMenu: true,
              // renderCell: (params) => {
              //   if (index === 2) {
              //     return (
              //       <a 
              //       style={{color: "blue", cursor: "pointer"}}
              //         href="#"
              //         onClick={(e) => {
              //           e.preventDefault();
              //           handleLinkClick(params.row);
              //         }}
              //       >
              //         {params.value}
              //       </a>
              //     );
              //   }
              //   return params.value;
              // },
            }))}
          rows={filteredData}
          checkboxSelection
          density="standard"
          slots={{
            toolbar: () => (
              <CustomToolbar
              columns={columns}
              selectedColumn={selectedColumn}
              setSelectedColumn={setSelectedColumn}
              operator={operator}
              setOperator={setOperator}
              onFilterApply={handleFilterApply}
              rowSelectionData={rowSelectionData}
              setopenserverenvmodal1={setopenserverenvmodal1}

            />
            ),
          }}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          keepNonExistentRowsSelected
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
            handleSelectionChange(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          autosizeOnMount={true}
          disableRowSelectionOnClick
        />
      </Box>
    </Box>
  );
}

export default Compute;