import { createContext, useState, useEffect, useRef } from "react";
import Table from "./components/Table";
import { callMsGraph } from "./graph";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication,
} from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import { ToastContainer, toast } from "react-toastify";
import { RumInit } from "@maersk-global/telemetry-web-sdk";

const faro = RumInit({
  app: {
    name: "MSA-DEV",
    version: "1.0",
  },
  apiKey: process.env.REACT_APP_RUM_API_KEY,
  debug: false, // false: Prevent showing log,error,etc captured in browser console
  traceLog: false, // Sends Traces to Loki ( For easy querying of traces)
  instrumentations: {
    fetch: true,
    console: false,
    error: false,
    interactions: true,
  },
});

// import jwt from 'jsonwebtoken';

export const TableContext = createContext(null);

/**
 * Context component that provides state and functions to its children components.
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {ReactNode} The rendered component.
 */
/**
 */
/**
 * Context component that provides various state and functions related to application ownership, certification, and user details.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} props.children - The child components to be rendered within the context provider.
 *
 * @returns {JSX.Element} The context provider component.
 *
 * @example
 * <Context>
 *   <YourComponent />
 * </Context>
 *
 * @context
 * @property {Object} table1 - State for table1 data.
 * @property {Function} setTable1 - Function to set table1 data.
 * @property {Object} graphData - State for graph data.
 * @property {Function} setGraphData - Function to set graph data.
 * @property {Array} data - State for data array.
 * @property {Function} setData - Function to set data array.
 * @property {Function} fetchUserDetails - Function to fetch user details.
 * @property {string} applicationName - State for application name.
 * @property {Function} setapplicationName - Function to set application name.
 * @property {Array} deletedRows - State for deleted rows.
 * @property {Function} setDeletedRows - Function to set deleted rows.
 * @property {boolean} showCommit - State for showing commit.
 * @property {Function} setshowCommit - Function to set showing commit.
 * @property {boolean} rated - State for rated.
 * @property {Function} setRated - Function to set rated.
 * @property {string} applicationId - State for application ID.
 * @property {Function} setApplicationId - Function to set application ID.
 * @property {string} token - State for token.
 * @property {boolean} showCertify - State for showing certify.
 * @property {Function} setShowCertify - Function to set showing certify.
 * @property {boolean} disableCertify - State for disabling certify.
 * @property {Function} setDisableCertify - Function to set disabling certify.
 * @property {boolean} certificationSuccessful - State for certification success.
 * @property {Function} setCertificationSuccessful - Function to set certification success.
 * @property {string} certificationResult - State for certification result.
 * @property {Function} setCertificationResult - Function to set certification result.
 * @property {boolean} showCertify1 - State for showing certify1.
 * @property {Function} setShowCertify1 - Function to set showing certify1.
 * @property {boolean} disableCertify1 - State for disabling certify1.
 * @property {Function} setDisableCertify1 - Function to set disabling certify1.
 * @property {boolean} certificationSuccessful1 - State for certification success1.
 * @property {Function} setCertificationSuccessful1 - Function to set certification success1.
 * @property {string} certificationResult1 - State for certification result1.
 * @property {Function} setCertificationResult1 - Function to set certification result1.
 * @property {string} showTooltip1 - State for showing tooltip1.
 * @property {Function} setShowTooltip1 - Function to set showing tooltip1.
 * @property {string} nextCertify1 - State for next certify1.
 * @property {Function} setNextCertify1 - Function to set next certify1.
 * @property {Function} handleLogout - Function to handle logout.
 * @property {string} nextCertify - State for next certify.
 * @property {Function} setNextCertify - Function to set next certify.
 * @property {Function} fetchData - Function to fetch data.
 * @property {string} applicationOwner - State for application owner.
 * @property {Function} setApplicationOwner - Function to set application owner.
 * @property {boolean} retired - State for retired.
 * @property {Function} setRetired - Function to set retired.
 * @property {number} count - State for count.
 * @property {Function} setCount - Function to set count.
 * @property {boolean} isOwner - State for is owner.
 * @property {Function} setIsOwner - Function to set is owner.
 * @property {Function} getCount - Function to get count.
 * @property {string} showTooltip - State for showing tooltip.
 * @property {Function} setShowTooltip - Function to set showing tooltip.
 * @property {string} certifyapplicationName - State for certify application name.
 * @property {Function} setcertifyapplicationName - Function to set certify application name.
 * @property {boolean} autosearch - State for auto search.
 * @property {Function} setautosearch - Function to set auto search.
 * @property {Object} ownershipdata - State for ownership data.
 * @property {Function} setownershipdata - Function to set ownership data.
 * @property {boolean} isciadmingrp - State for is CI admin group.
 * @property {Function} setisciadmingrp - Function to set is CI admin group.
 * @property {string} ownershipTooltip - State for ownership tooltip.
 * @property {Function} setownershipTooltip - Function to set ownership tooltip.
 * @property {string} certifypercentage - State for certify percentage.
 * @property {Function} setcertifypercentage - Function to set certify percentage.
 * @property {number} tableRightBoundary - State for table right boundary.
 * @property {Function} setTableRightBoundary - Function to set table right boundary.
 * @property {number} tableBottomtBoundary - State for table bottom boundary.
 * @property {Function} setTableBottomBoundary - Function to set table bottom boundary.
 * @property {string} appenv - State for app environment.
 * @property {Function} setappenv - Function to set app environment.
 * @property {boolean} relationcertified - State for relation certified.
 * @property {Function} setrelationcertified - Function to set relation certified.
 * @property {boolean} ownershipcertified - State for ownership certified.
 * @property {Function} setownershipcertified - Function to set ownership certified.
 * @property {Array} table4 - State for table4 data.
 * @property {Function} setTable4 - Function to set table4 data.
 * @property {boolean} isCiOwnership - State for is CI ownership.
 * @property {Function} setIsCiOwnership - Function to set is CI ownership.
 * @property {boolean} autosearch1 - State for auto search1.
 * @property {Function} setautosearch1 - Function to set auto search1.
 * @property {boolean} spinnerDisplay - State for spinner display.
 * @property {Function} setSpinnerDisplay - Function to set spinner display.
 * @property {Function} fetchOwnership - Function to fetch ownership.
 * @property {boolean} showcreatebutton - State for showing create button.
 * @property {Function} setshowcreatebutton - Function to set showing create button.
 * @property {string} showTooltip2 - State for showing tooltip2.
 * @property {Function} setShowTooltip2 - Function to set showing tooltip2.
 * @property {Object} table5 - State for table5 data.
 * @property {Function} setTable5 - Function to set table5 data.
 * @property {Array} relationshipCategory - Array of relationship categories.
 * @property {Object} relationshipCategorySelected - State for selected relationship category.
 * @property {Function} setRelationshipCategorySelected - Function to set selected relationship category.
 * @property {number} tabIndex - State for tab index.
 * @property {Function} setTabIndex - Function to set tab index.
 * @property {Function} fetchWithTimeout - Function to fetch with timeout.
 * @property {boolean} disableCertify1apptoapp - State for disabling certify1 app to app.
 * @property {Function} setDisableCertify1apptoapp - Function to set disabling certify1 app to app.
 * @property {string} showTooltip2apptoapp - State for showing tooltip2 app to app.
 * @property {Function} setShowTooltip2apptoapp - Function to set showing tooltip2 app to app.
 * @property {string} showTooltip1apptoapp - State for showing tooltip1 app to app.
 * @property {Function} setShowTooltip1apptoapp - Function to set showing tooltip1 app to app.
 * @property {boolean} relationcertifiedapptoapp - State for relation certified app to app.
 * @property {Function} setrelationcertifiedapptoapp - Function to set relation certified app to app.
 * @property {Array} appData - State for app data.
 * @property {Function} setAppData - Function to set app data.
 * @property {Function} fetchAppData - Function to fetch app data.
 * @property {boolean} selectedSuggestion - State for selected suggestion.
 * @property {Function} setSelectedSuggestion - Function to set selected suggestion.
 * @property {Object} macDetails - State for MAC details.
 * @property {Function} setMacDetails - Function to set MAC details.
 * @property {boolean} isRetired - State for is retired.
 * @property {Function} setIsRetired - Function to set is retired.
 * @property {boolean} forOtherUser - State for other user.
 * @property {Function} setForOtherUser - Function to set for other user.
 * @property {Function} handleOtherUserInfo - Function to handle other user info.
 * @property {Array} cloudData - State for cloud data.
 * @property {Function} setCloudData - Function to set cloud data.
 * @property {Array} subscriptionSuggestions - State for subscription suggestions.
 * @property {Function} setSubscriptionSuggestions - Function to set subscription suggestions.
 * @property {Array} table8 - State for table8 data.
 * @property {Function} setTable8 - Function to set table8 data.
 * @property {Array} serverData - State for server data.
 * @property {Function} setServerData - Function to set server data.
 * @property {Function} fetchServerData - Function to fetch server data.
 * @property {string} inputserverName - State for input server name.
 * @property {Function} setInputserverName - Function to set input server name.
 * @property {boolean} applicationManagerDisplay - State for application manager display.
 * @property {Function} setApplicationManagerDisplay - Function to set application manager display.
 * @property {boolean} suggestionSelected - State for suggestion selected.
 * @property {Function} setSuggestionSelected - Function to set suggestion selected.
 * @property {string} appStatus - State for app status.
 * @property {Function} setAppStatus - Function to set app status.
 * @property {Array} retiredApps - State for retired apps.
 * @property {Function} setretiredApps - Function to set retired apps.
 * @property {boolean} showOwner - State for showing owner.
 * @property {Function} setShowOwner - Function to set showing owner.
 * @property {boolean} commondmodalopen - State for common modal open.
 * @property {Function} setcommondmodalopen - Function to set common modal open.
 * @property {Function} handleCommonModalClose - Function to handle common modal close.
 * @property {boolean} showSubscribe - State for showing subscribe.
 * @property {Function} setShowSubscribe - Function to set showing subscribe.
 * @property {boolean} showUnsubscribe - State for showing unsubscribe.
 * @property {Function} setShowUnsubscribe - Function to set showing unsubscribe.
 */
function Context({ children }) {
  const { instance, accounts } = useMsal();
  const [table1, setTable1] = useState();
  const [applicationName, setapplicationName] = useState("");
  const [certifyapplicationName, setcertifyapplicationName] = useState("");
  const [graphData, setGraphData] = useState(null);
  const [deletedRows, setDeletedRows] = useState([]);
  const [showCommit, setshowCommit] = useState(false);
  const [rated, setRated] = useState(false);
  const [applicationId, setApplicationId] = useState("");
  const [applicationOwner, setApplicationOwner] = useState("");
  const [retired, setRetired] = useState(false);
  const [count, setCount] = useState(0);
  const [isOwner, setIsOwner] = useState("");
  const [token, setToken] = useState(null);
  const [showCertify, setShowCertify] = useState(false);
  const [disableCertify, setDisableCertify] = useState(false);
  const [showSubscribe, setShowSubscribe] = useState(false);
  const [showUnsubscribe, setShowUnsubscribe] = useState(false);
  const [showSaaS, setShowSaaS] = useState(false);
  const [disableSaaS, setDisableSaaS] = useState(false);
  const [showSaaS1, setShowSaaS1] = useState(false);
  const [disableSaaS1, setDisableSaaS1] = useState(false);
  const [markedAsSaaS, setMarkedAsSaaS] = useState(false);
  const [certificationSuccessful, setCertificationSuccessful] = useState(false);
  const [certificationResult, setCertificationResult] = useState("");
  const [nextCertify, setNextCertify] = useState("");
  const [isciadmingrp, setisciadmingrp] = useState(false);
  const [certifypercentage, setcertifypercentage] = useState("");
  const [tableRightBoundary, setTableRightBoundary] = useState(0);
  const [tableBottomtBoundary, setTableBottomBoundary] = useState(0);
  const [appStatus, setAppStatus] = useState();
  const [retiredApps, setretiredApps] = useState();
    const [ownedApplications, setOwnedApplications] = useState([]);
  

  const [showCertify1, setShowCertify1] = useState(false);
  const [disableCertify1, setDisableCertify1] = useState(true);
  const [disableCertify1apptoapp, setDisableCertify1apptoapp] = useState(true);

  const [certificationSuccessful1, setCertificationSuccessful1] =
    useState(false);
  const [relationcertified, setrelationcertified] = useState(false);
  const [relationcertifiedapptoapp, setrelationcertifiedapptoapp] =
    useState(false);

  const [ownershipcertified, setownershipcertified] = useState(false);
  const [showcreatebutton, setshowcreatebutton] = useState(false);
  const [subscriptionSuggestions, setSubscriptionSuggestions] = useState([]);
  const [inputserverName, setInputserverName] = useState("");
  const [suggestionSelected, setSuggestionSelected] = useState(false);

  const [certificationResult1, setCertificationResult1] = useState("");
  const [nextCertify1, setNextCertify1] = useState("");
  const [appenv, setappenv] = useState("");

  const [data, setData] = useState([]);
  const [showTooltip, setShowTooltip] = useState("");
  const [showTooltip1, setShowTooltip1] = useState("");
  const [showTooltip1apptoapp, setShowTooltip1apptoapp] = useState("");
  const [showSaaSTooltip, setShowSaaSTooltip] = useState("");
  const [showSaaSTooltip1, setShowSaaSTooltip1] = useState("");

  const [showTooltip2, setShowTooltip2] = useState("");
  const [showTooltip2apptoapp, setShowTooltip2apptoapp] = useState("");

  const [ownershipTooltip, setownershipTooltip] = useState("");
  const [autosearch, setautosearch] = useState(false);
  const [table4, setTable4] = useState([]);
  const [cloudData, setCloudData] = useState([]);
  const [isCiOwnership, setIsCiOwnership] = useState(false);
  const [autosearch1, setautosearch1] = useState(false);
  const [spinnerDisplay, setSpinnerDisplay] = useState(true);
  const [searchbarName, setSearchbarName] = useState("");
  const [arrowStatus, setArrowStatus] = useState(false);
  const [macDetails, setMacDetails] = useState();
  const [ownershipdata, setownershipdata] = useState({
    managed_by: { displayValue: "", value: "", email: "" },
    owned_by: { displayValue: "", value: "", email: "" },
    sys_id: "",
    u_mvc: { displayValue: "" },
    u_engineering_manager: { displayValue: "", value: "", email: "" },
    u_sr_engineering_manager: { displayValue: "", value: "", email: "" },
    u_engineering_director: { displayValue: "", value: "", email: "" },
    operational_status: { displayValue: "", value: "", email: "" },
    u_ci_admin_group: { displayValue: "", value: "", email: "" },
    u_ci_admin_group: { displayValue: "", value: "", email: "" },
    u_environment: { displayValue: "", value: "", email: "" },
    vendor: "",
  });
  const [table5, setTable5] = useState();
  const relationshipCategory = [
    { value: "", label: "Select an option", isDisabled: true },
    { value: "app_to_server", label: "App to Server" },
    { value: "app_to_app", label: "App to App" },
    { value: "app_to_cloud", label: "App to Cloud" },
    { value: "server_to_app", label: "Server to App" },
    { value: "app_to_repository", label: "App to Repository" },
  ];
  const [relationshipCategorySelected, setRelationshipCategorySelected] =
    useState(relationshipCategory[0]);
  const [tabIndex, setTabIndex] = useState(0);
  const [appData, setAppData] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(false);
  const [isRetired, setIsRetired] = useState(false);
  const [forOtherUser, setForOtherUser] = useState(false);
  const [table8, setTable8] = useState([]);
  const [repoData, setRepoData] = useState([]);
  const [repoSuggestions, setRepoSuggestions] = useState([]);
  const [serverData, setServerData] = useState([]);
  const [applicationManagerDisplay, setApplicationManagerDisplay] =
    useState(true);
  const [showOwner, setShowOwner] = useState(true);
  const [commondmodalopen, setcommondmodalopen] = useState(false);
  const [repoCiOwner, setRepoCiOwner] = useState(false);
  useEffect(() => {
    fetchUserDetails();
    //  getToken()
    //  console.log("graphData",graphData)
  }, [accounts]);
   useEffect(() => {
    if (graphData && token) {
      getToken().then(() => {
        getCount();
      });
    }
  }, [graphData, token]);
 
  useEffect(() => {
    if (graphData?.displayName !== undefined) {
      // getOwnerApplications();
      
      sendUserInsights(graphData);
      faro.api.setUser({
        id: graphData?.mail,
        email:graphData?.mail,
        username:graphData?.displayName,
      })
    }
  }, [graphData?.displayName]);
  const fetchUserDetails = async () => {
    try {
      await instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          callMsGraph(response.accessToken).then((response) =>
            setGraphData(response)
          );
          setToken(response.accessToken);
        });
    } catch (error) {
      console.log(error);
    }
    return true;
  };

  const fetchWithTimeout = async (url, options, timeout = 200000) => {
    try {
      const response = await Promise.race([
        fetch(url, options),
        new Promise((_, reject) =>
          setTimeout(() => reject(new Error("Timeout")), timeout)
        ),
      ]);
      return response;
    } catch (error) {
      // toast.error("Request Timed Out", {
      //   position: "top-center",
      //   autoClose: 3000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
      throw error;
    }
  };

  const getOwnerApplications = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/UserOwnedApplication`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            logged_in_user :graphData.displayName,
            logged_in_user_email: graphData.mail,
          }),
        }
      );
      const data=await response.json();
      console.log("Data:", data);
      setOwnedApplications(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchOwnership = async () => {
    setTable4([]);
    setSpinnerDisplay(true);
    try {
      // console.log("GraphData",graphData?.displayName);
      const response = await fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URI}/applicationOwnership`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            logged_in_user: graphData?.displayName,
            logged_in_user_email: graphData?.mail,
            graph_access_token: token,
          }),
        }
      );
      if (response.ok) {
        const result = await response.json();
        if (result.length > 0 && result != "no ownership exists") {
          setTable4(result);
          console.log("result", result);
          setForOtherUser(false);
          setIsCiOwnership(true);
          setSpinnerDisplay(false);
        }
      } else {
        setTable4([]);
        setIsCiOwnership(false);
        setSpinnerDisplay(false);

        console.log("Failed to fetch data");
      }
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setIsCiOwnership(false);
      setSpinnerDisplay(false);
      console.log("Error", error);
    }
    setSpinnerDisplay(false);
  };

  const getToken = async () => {
    const token1 = token;
    const response = await fetchWithTimeout(`${process.env.REACT_APP_BACKEND_URI}/getToken`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token1}`,
      },
      body: JSON.stringify({ userEmail: graphData?.mail })
    });
    const data = await response.json();
    // console.log("data", data);
     localStorage.setItem("token", data);
     return data
  };
  const handleLogout = async () => {
    try {
      // Ensure that 'instance' is your MSAL.js instance (UserAgentApplication or PublicClientApplication)
      // If using PublicClientApplication, you might not have handleRedirectCallback
      if (instance.handleRedirectCallback) {
        // Wait for any ongoing interaction to complete
        await instance.handleRedirectCallback();
      }
      // Replace 'accounts' with the correct account object
      const accounts = instance.getAllAccounts()[0]; // Retrieve the first account, adjust as needed
      // Perform the logout operation
      await instance.logoutRedirect({
        account: accounts,
        postLogoutRedirectUri: null,
      });
      // Clear the token cache after successful logout
      instance.clearCache();
    } catch (error) {
      console.error("Error during logout:", error);
      // Handle error as needed
    }
  };
  const fetchData = async () => {
    try {
      const response = await fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URI}/getdata1`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ applicationName: applicationName }),
        }
      );
      if (response.ok) {
        const result = await response.json();
        console.log("TEST", result);
        setData(result);
        // updateTable1(result);
        // console.log('table1:', table1);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      console.error("An error occurred:", error);
    }
  };
  const getCount = async () => {
    try {
      const response = await fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URI}/getcount`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ username: graphData?.displayName }),
        }
      );
      const result = await response.json();
      setCount(result.count);
      setIsOwner(result.isadmin);
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      console.error("An error occurred:", error);
    }
  };

  const fetchAppData = async () => {
    try {
      const response = await fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URI}/getdata2`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ applicationName: applicationName }),
        }
      );
      if (response.ok) {
        const result = await response.json();
        console.log("TEST", result);
        setAppData(result);
        // updateTable1(result);
        // console.log('table1:', table1);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      console.error("An error occurred:", error);
    }
  };

  const handleOtherUserInfo = async (usernamevalue, useremailvalue) => {
    setTable4([]);
    setIsCiOwnership(false);
    setSpinnerDisplay(true);
    try {
      // console.log("GraphData",graphData?.displayName);
      const response = await fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URI}/applicationOwnership`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            logged_in_user: usernamevalue,
            logged_in_user_email: useremailvalue,
            graph_access_token: token,
          }),
        }
      );
      if (response.ok) {
        const result = await response.json();
        if (result.length > 0 && result != "no ownership exists") {
          setTable4(result);
          console.log("result", result);
          setIsCiOwnership(true);
          setForOtherUser(true);
          setSpinnerDisplay(false);
        }
      } else {
        setTable4([]);
        setIsCiOwnership(false);
        setSpinnerDisplay(false);

        console.log("Failed to fetch data");
      }
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setIsCiOwnership(false);
      setSpinnerDisplay(false);
      console.log("Error", error);
    }
    setSpinnerDisplay(false);
  };
  const handleCommonModalClose = () => {
    setcommondmodalopen(false);
  };

  const fetchServerData = async () => {
    try {
      const response = await fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URI}/getdata3`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ applicationName: inputserverName }),
        }
      );
      if (response.ok) {
        const result = await response.json();
        console.log("table8 req data", result);
        setServerData(result);
        // updateTable1(result);
        // console.log('table1:', table1);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      console.error("An error occurred:", error);
    }
  };

  const sendUserInsights = async (data) => {
    try {
      const response = await fetchWithTimeout(`${process.env.REACT_APP_BACKEND_URI}/storeuserinsights`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      });
      const data1 = await response.json();
      console.log("Insights API called with response", data1);
    } catch (error) {
      if (error.message === "Timeout") {
        console.log("Request Timed Out");
      }
      console.error("An error occurred:", error);
    }
  };

  //  const user=fetchUserDetails()
  return (
    <TableContext.Provider
      value={{
        table1,
        setTable1,
        graphData,
        setGraphData,
        data,
        setData,
        fetchUserDetails,
        applicationName,
        setapplicationName,
        deletedRows,
        setDeletedRows,
        showCommit,
        setshowCommit,
        rated,
        setRated,
        applicationId,
        setApplicationId,
        token,
        showCertify,
        setShowCertify,
        disableCertify,
        setDisableCertify,
        showSubscribe,
        setShowSubscribe,
        showUnsubscribe,
        setShowUnsubscribe,
        certificationSuccessful,
        setCertificationSuccessful,
        certificationResult,
        setCertificationResult,
        showCertify1,
        setShowCertify1,
        disableCertify1,
        setDisableCertify1,
        certificationSuccessful1,
        setCertificationSuccessful1,
        certificationResult1,
        setCertificationResult1,
        showTooltip1,
        setShowTooltip1,
        nextCertify1,
        setNextCertify1,
        handleLogout,
        nextCertify,
        setNextCertify,
        fetchData,
        applicationOwner,
        setApplicationOwner,
        retired,
        setRetired,
        count,
        setCount,
        isOwner,
        setIsOwner,
        getCount,
        showTooltip,
        setShowTooltip,
        certifyapplicationName,
        setcertifyapplicationName,
        autosearch,
        setautosearch,
        ownershipdata,
        setownershipdata,
        isciadmingrp,
        setisciadmingrp,
        ownershipTooltip,
        setownershipTooltip,
        certifypercentage,
        setcertifypercentage,
        tableRightBoundary,
        setTableRightBoundary,
        tableBottomtBoundary,
        setTableBottomBoundary,
        appenv,
        setappenv,
        relationcertified,
        setrelationcertified,
        ownershipcertified,
        setownershipcertified,
        table4,
        setTable4,
        isCiOwnership,
        setIsCiOwnership,
        autosearch1,
        setautosearch1,
        spinnerDisplay,
        setSpinnerDisplay,
        fetchOwnership,
        showcreatebutton,
        setshowcreatebutton,
        showTooltip2,
        setShowTooltip2,
        table5,
        setTable5,
        relationshipCategory,
        relationshipCategorySelected,
        setRelationshipCategorySelected,
        tabIndex,
        setTabIndex,
        fetchWithTimeout,
        disableCertify1apptoapp,
        setDisableCertify1apptoapp,
        showTooltip2apptoapp,
        setShowTooltip2apptoapp,
        showTooltip1apptoapp,
        setShowTooltip1apptoapp,
        relationcertifiedapptoapp,
        setrelationcertifiedapptoapp,
        appData,
        setAppData,
        fetchAppData,
        selectedSuggestion,
        setSelectedSuggestion,
        macDetails,
        setMacDetails,
        isRetired,
        setIsRetired,
        forOtherUser,
        setForOtherUser,
        handleOtherUserInfo,
        cloudData,
        setCloudData,
        subscriptionSuggestions,
        setSubscriptionSuggestions,
        table8,
        setTable8,
        repoData,
        setRepoData,
        repoSuggestions,
        setRepoSuggestions,
        serverData,
        setServerData,
        fetchServerData,
        inputserverName,
        setInputserverName,
        applicationManagerDisplay,
        setApplicationManagerDisplay,
        suggestionSelected,
        setSuggestionSelected,
        appStatus,
        setAppStatus,
        retiredApps,
        setretiredApps,
        showOwner,
        commondmodalopen,
        setcommondmodalopen,
        handleCommonModalClose,
        searchbarName,
        setSearchbarName,
        arrowStatus,
        setArrowStatus,
        setShowOwner,
        repoCiOwner, 
        setRepoCiOwner,
        showSaaS,
        setShowSaaS,
        disableSaaS,
        setDisableSaaS,
        showSaaSTooltip,
        setShowSaaSTooltip,
        showSaaS1,
        setShowSaaS1,
        disableSaaS1,
        setDisableSaaS1,
        showSaaSTooltip1,
        setShowSaaSTooltip1,
        markedAsSaaS,
        setMarkedAsSaaS,
        getToken,
        ownedApplications, setOwnedApplications,
      }}
    >
      {children}
    </TableContext.Provider>
  );
}
export default Context;
